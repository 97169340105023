"use client";

import React, { FC, useEffect, useState } from "react";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@ui/form";
import { Input } from "@ui/input";
import { Button } from "@ui/button";
import { LoaderCircleIcon } from "lucide-react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { signIn } from "next-auth/react";
import { useTranslations } from "next-intl";
import { useRouter, useSearchParams } from "next/navigation";
import { Link } from "~/i18n/routing";

export const LoginForm: FC = () => {
    const t = useTranslations();
    const router = useRouter();
    const searchParams = useSearchParams();
    const callbackUrlQuery = searchParams.get("callbackUrl");
    const emailQuery = searchParams.get("email");

    const [loginFailed, setLoginFailed] = useState(false);
    const [loading, setLoading] = useState(false);

    const FormSchema = z.object({
        email: z.string().min(1, t("requiredFieldMessage")),
        password: z.string().min(1, t("requiredFieldMessage")),
    });

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: { email: "", password: "" },
    });

    useEffect(() => {
        if (emailQuery) {
            form.setValue("email", emailQuery);
        }
    }, [form, emailQuery]);

    async function onSubmit(data: z.infer<typeof FormSchema>) {
        setLoginFailed(false);
        setLoading(true);

        const result = await signIn("credentials", { redirect: false, ...data });

        if (result?.ok) {
            router.push(callbackUrlQuery || "/");
        } else {
            setLoginFailed(true);
            setLoading(false);
        }
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-4">
                <FormField
                    control={form.control}
                    name="email"
                    disabled={loading}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>{t("email.label")}</FormLabel>
                            <FormControl>
                                <Input {...field} placeholder={t("email.placeholder")} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="password"
                    disabled={loading}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>{t("password.label")}</FormLabel>
                            <FormControl>
                                <Input {...field} type="password" placeholder={t("password.placeholder")} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <Button disabled={loading} type="submit">
                    {loading && <LoaderCircleIcon className="mr-2 h-4 w-4 animate-spin" />}
                    {t("logIn")}
                </Button>

                {loginFailed && <div className="text-center text-red-600">{t("invalidCredentialsMessage")}</div>}

                <Link href="/forgot-password" className="text-right text-sm text-muted-foreground hover:underline">
                    {t("forgotPassword")}?
                </Link>
            </form>
        </Form>
    );
};
